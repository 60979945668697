import React from 'react'
import BuzzImg from './images/buzz-logo.gif'


const About = () => {
  return (
    <div className="bg-aboutUsBg">
        <div className="px-12 sm:px-5 xs:px-3">
          <div className="py-32 flex justify-center">
            <img src={BuzzImg} alt="logo" className='w-[420px]' />
          </div>
        </div>
    </div>
  )
}

export default About