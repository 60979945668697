import React from 'react'
import Wastewater from './images/Wastewater-Treatment.png';
import six from './images/6.png';
import eight from './images/8.png';
import ten from './images/10.png';
import twelve from './images/12.png';
import fifteen from './images/15.png';
import RoWater from './images/ROWater.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Itemsix = () => {
  return (
    <>
    <div className="grid grid-cols-2 gap-2 md:grid-cols-6 sm:grid-cols-3 text-center">
  <div>
    <div className='flex justify-center items-center'> <img src={Wastewater} alt="Wastewater" className='w-[36%]'/></div>
    <div className='text-base font-light tracking-normal'>Wastewater Treatment</div>
  </div>
  <div>
    <div className='flex justify-center items-center'> <img src={six} alt="six" className='w-[36%]'/></div>
    <div className='text-base font-light tracking-normal'>Water Treatment</div>
  </div>
  <div>
    <div className='flex justify-center items-center'> <img src={eight} alt="eight" className='w-[36%]'/></div>
    <div className='text-base font-light tracking-normal'>Reverse Osmosis Plant</div>
  </div>
  <div>
    <div className='flex justify-center items-center'> <img src={ten} alt="ten" className='w-[36%]'/></div>
    <div className='text-base font-light tracking-normal'>Sludge Free Bioremediation</div>
  </div>
  <div>
    <div className='flex justify-center items-center'> <img src={twelve} alt="twelve" className='w-[36%]'/></div>
    <div className='text-base font-light tracking-normal'>Packaged Type Systems</div>
  </div>
  <div>
    <div className='flex justify-center items-center'> <img src={fifteen} alt="fifteen" className='w-[36%]'/></div>
    <div className='text-base font-light tracking-normal'>Drinking water treatment</div>
  </div>
</div>
<div className="grid grid-cols-1 md:grid-cols-2  p-8">
  <div className=" p-7">
    <h6 className="text-xl font-bold">Facilitech Engineering Enterprises</h6>
    <h2 className="text-5xl font-bold leading-10">A Total Water Treatment Company</h2>
    <p className="text-gray-600 mt-2">Facilitech Water Services is a water treatment company
     that seeks to offer and provided value added chemical solutions and services 
     in critical market applications. The collective experience is over 15 years 
     in market such as oil & Gas, Textile, Tobacco, Ghee, Sugar, Cement, Captive 
     Power Plants, IPP's as well as many industrial applications.
    Facilitech Water Services has the best sales & after sales technical services in 
    order to provide the most cost effective water treatment chemical solution to 
    clients by combining excellent products with years to technical expertise and 
    exceptional service ethic, Facilitech Water Services seeks to become your first 
    choice for water treatment and services.</p>
    <button className="bg-blue-500 hover:bg-blue-400 mt-5 text-white rounded-full font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded">
  Contact Us
</button>
  </div>
  
  <div className="p-4">
    <img src={RoWater} alt="Descriptive Alt Text" className="w-full h-auto object-cover rounded-2xl"/>
  </div>
</div>
<div className="grid grid-cols-1 md:grid-cols-2 mb-4">
  <div className='m-8 border-2 border-blue-400'>
  <div className=" bg-gray-300 p-6 text-center hover:bg-gray-500 hover:text-white border-r-2">
  <i className="fa-brands fa-phoenix-squadron fa-5x"></i>
<h2 className='text-2xl leading-7 tracking-wider my-3'>Our Services</h2>
<p className='text-lg'>Dependably providing a comprehensive package of quality chemicals & back up technical Services at very attractive cost If your present treatment is the base of expensive shut-downs, contact your FEE’s representatives and let them prove you, how truly superior products & Services cut your costs .</p>
<button className="bg-blue-200 hover:bg-blue-400 mt-5 text-white rounded-full font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded">
  Read More
</button>
  </div>
  </div>
  <div className='m-8 border-2 border-blue-400'>
  <div className=" bg-gray-300 p-6 text-center hover:bg-gray-500 hover:text-white">
  <i className="fa-brands fa-life-ring fa-5x"></i>
  <h2 className='text-2xl leading-7 tracking-wider my-3'>Our Products</h2>
<p className='text-lg'>Dependably providing a comprehensive package of quality chemicals & back up technical Services at very attractive cost If your present treatment is the base of expensive shut-downs, contact your FEE’s representatives and let them prove you, how truly superior products & Services cut your costs .</p>
<button className="bg-blue-200 hover:bg-blue-400 mt-5 text-white rounded-full font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded">
  Read More
</button>
  </div>
  </div>
</div>
<div className="mx-auto my-10">
  <div className="flex flex-col md:grid grid-cols-9 mx-auto p-2 text-blue-50">

    <div className="flex md:contents">
      <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
        <div className="h-full w-10 flex items-center justify-center">
          <div className="h-[50px] mt-[85px] w-1 bg-blue-500 pointer-events-none"></div>
        </div>
        <div className="w-10 h-10 absolute top-1/2 -mt-3 rounded-full bg-blue-500 shadow rounded-none transform rotate-45"></div>
      </div>
      <div className="col-start-6 col-end-9 p-4 rounded-xl my-4 ml-auto shadow-md bg-blue-500">
        <h3 className="font-semibold text-lg mb-1">Mineral Water for Drinking</h3>
        <p className="leading-tight text-justify">
        19 Liter Bottle, 06 Liter Bottle, 1.5 Liter Bottle, 600 ml bottle,
        </p>
      </div>
    </div>
    <div className="flex md:contents">
      <div className="col-start-2 col-end-5 p-4 rounded-xl my-4 mr-auto shadow-md bg-blue-500">
        <h3 className="font-semibold text-lg mb-1">Water Treatment Chemicals</h3>
        <p className="leading-tight text-justify">
        Corrosion & Scale Inhibitors, Scale inhibitors / Dispersants, Biocides, Biodispersants, Core cleaners
        </p>
      </div>
      <div className="col-start-5 col-end-6 md:mx-auto relative">
        <div className="h-full w-10 flex items-center justify-center">
          <div className="h-full w-1 bg-blue-500 pointer-events-none"></div>
        </div>
        <div className="w-10 h-10 absolute top-1/2 -mt-3 rounded-full bg-blue-500 shadow  rounded-none transform rotate-45"></div>
      </div>
    </div>
    <div className="flex md:contents">
      <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
        <div className="h-full w-10 flex items-center justify-center">
          <div className="h-full w-1 bg-blue-500 pointer-events-none"></div>
        </div>
        <div className="w-10 h-10 absolute top-1/2 -mt-3 rounded-full bg-blue-500 shadow  rounded-none transform rotate-45"></div>
      </div>
      <div className="col-start-6 col-end-9 p-4 rounded-xl my-4 ml-auto shadow-md bg-blue-500">
        <h3 className="font-semibold text-lg mb-1">Effluent Treatment Chemicals</h3>
        <p className="leading-tight text-justify">
        Anionic Polyacrlyamide, Cationic Polyacrylamide, Poly Aluminum Chloride, Alum, Lime
        </p>
      </div>
    </div>
    <div className="flex md:contents">
      <div className="col-start-2 col-end-5 p-4 rounded-xl my-4 mr-auto shadow-md bg-blue-500">
        <h3 className="font-semibold text-lg mb-1">Water Treatment Chemicals</h3>
        <p className="leading-tight text-justify">
        Corrosion & Scale Inhibitors, Scale inhibitors / Dispersants, Biocides, Biodispersants, Core cleaners
        </p>
      </div>
      <div className="col-start-5 col-end-6 md:mx-auto relative">
        <div className="h-full w-10 flex items-center justify-center">
          <div className="h-full w-1 bg-blue-500 pointer-events-none"></div>
        </div>
        <div className="w-10 h-10 absolute top-1/2 -mt-3 rounded-full bg-blue-500 shadow  rounded-none transform rotate-45"></div>
      </div>
    </div>
    <div className="flex md:contents">
      <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
        <div className="h-full w-10 flex items-center justify-center">
          <div className="h-full w-1 bg-blue-500 pointer-events-none"></div>
        </div>
        <div className="w-10 h-10 absolute top-1/2 -mt-3 rounded-full bg-blue-500 shadow  rounded-none transform rotate-45"></div>
      </div>
      <div className="col-start-6 col-end-9 p-4 rounded-xl my-4 ml-auto shadow-md bg-blue-500">
        <h3 className="font-semibold text-lg mb-1">Effluent Treatment Chemicals</h3>
        <p className="leading-tight text-justify">
        Anionic Polyacrlyamide, Cationic Polyacrylamide, Poly Aluminum Chloride, Alum, Lime
        </p>
      </div>
    </div>
    <div className="flex md:contents">
      <div className="col-start-2 col-end-5 p-4 rounded-xl my-4 mr-auto shadow-md bg-blue-500">
        <h3 className="font-semibold text-lg mb-1">Water Treatment Chemicals</h3>
        <p className="leading-tight text-justify">
        Corrosion & Scale Inhibitors, Scale inhibitors / Dispersants, Biocides, Biodispersants, Core cleaners
        </p>
      </div>
      <div className="col-start-5 col-end-6 md:mx-auto relative">
        <div className="h-full w-10 flex items-center justify-center">
          <div className="h-[49px] mb-[87px] w-1 bg-blue-500 pointer-events-none"></div>
        </div>
        <div className="w-10 h-10 absolute top-1/2 -mt-3 rounded-full bg-blue-500 shadow  rounded-none transform rotate-45"></div>
      </div>
    </div>
  </div>
</div>
<div className='p-8'>
  <h1 className='text-center my-3 text-4xl font-semibold leading-10'>Why FEE Services Pakistan?</h1>
  <div className='grid grid-cols-1 gap-2 sm:grid-cols-2'>
    <div>
        <div className="grid grid-cols-2  md:grid-cols-2 sm:grid-cols-1 text-center">
  <div className='my-4'>
    <div className='flex justify-center items-center'> <i aria-hidden="true" className="fa fa-burn text-6xl"></i></div>
    <div className='text-base font-light tracking-normal'>Better Solution</div>
  </div>
  <div className='my-4'>
    <div className='flex justify-center items-center'> <i aria-hidden="true" className="fa fa-lightbulb text-6xl"></i></div>
    <div className='text-base font-light tracking-normal'>Project Assistance</div>
  </div>
</div>
 <div className="grid grid-cols-2  md:grid-cols-2 sm:grid-cols-1 text-center">
  <div className='my-4'>
    <div className='flex justify-center items-center'> <i aria-hidden="true" className="fa fa-street-view text-6xl"></i></div>
    <div className='text-base font-light tracking-normal'>Action Research</div>
  </div>
  <div className='my-4'>
    <div className='flex justify-center items-center'> <i aria-hidden="true" className="fa fa-compress-alt text-6xl"></i></div>
    <div className='text-base font-light tracking-normal'>Planning</div>
  </div>
</div>
    </div>
    <div className='p-2'>
      <ul className='list-disc'>
        <li>Facilitech Water Services offers the most viable and eco-friendly solutions for your needs.</li>
        <li>We deliver innovative and affordable treatment systems tailored to your specific problems.</li>
        <li>Professional training is available, covering everything from installation to ongoing maintenance.</li>
        <li>As a leading service provider, we utilize cutting-edge technologies and have a highly skilled team.</li>
        <li>Comprehensive guidelines ensure exceptional results and operational excellence.</li>
      </ul>
    </div>
  </div>
</div>
</>
  )
}

export default Itemsix