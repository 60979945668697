import React, { useState } from 'react'
import { Link } from 'react-router-dom'


const Navbar = () => {

  return (
    <>
 <nav className="bg-gray-800 p-4">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <Link to="/" className="text-white text-xl font-bold"><span className='hidden lg:block'>Facilitech Engineering Enterprises</span><span className='block lg:hidden'>FEE</span></Link>

     

        {/* Navbar Links */}
        <div className="md:flex space-x-4 md:block">
          <Link to="/" className="text-gray-300 hover:text-white">Home</Link>
          <Link to="/about" className="text-gray-300 hover:text-white">About</Link>
          <Link to="/service" className="text-gray-300 hover:text-white">Service</Link>
          <Link to="/contact" className="text-gray-300 hover:text-white">Contact</Link>
        </div>
      </div>
    </nav>

    

    </>
  )
}

export default Navbar