import React from 'react'

const Service = () => {
  return (
    <div class="flex items-center justify-center min-h-screen bg-gray-800 text-white">
    <div class="text-center">
        <h1 class="text-5xl md:text-7xl font-bold">Coming Soon</h1>
    </div>
    </div>
  )
}

export default Service