import React from 'react'
import Itemsix from "./Itemsix";

const Home = () => {
  return (
    <>
    <Itemsix/>
    </>
  )
}

export default Home